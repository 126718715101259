exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-gala-jsx": () => import("./../../../src/pages/gala.jsx" /* webpackChunkName: "component---src-pages-gala-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/Article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-blog-root-jsx": () => import("./../../../src/templates/BlogRoot.jsx" /* webpackChunkName: "component---src-templates-blog-root-jsx" */),
  "component---src-templates-categories-archive-jsx": () => import("./../../../src/templates/CategoriesArchive.jsx" /* webpackChunkName: "component---src-templates-categories-archive-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/Category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-other-pages-jsx": () => import("./../../../src/templates/OtherPages.jsx" /* webpackChunkName: "component---src-templates-other-pages-jsx" */),
  "component---src-templates-tour-root-jsx": () => import("./../../../src/templates/TourRoot.jsx" /* webpackChunkName: "component---src-templates-tour-root-jsx" */)
}

